<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="false"
      bordered
    >
    </a-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: this.$route.params.breadName,
          url: '/account/' + this.$route.params.accountType + '/list'
        },
        {
          name: '结算卡管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      typeList: [],
      account: {
        accountId: this.$route.params.accountId,
        accountType: this.$route.params.accountType,
        breadName: this.$route.params.breadName
      },
      form: {
      },
      columns: [
        { title: '编号', width: 200, key: 'id', dataIndex: 'id' },
        { title: '账户编号', width: 200, key: 'accountId', dataIndex: 'accountId' },
        { title: '账户名称', width: 150, key: 'bankCardName', dataIndex: 'bankCardName' },
        { title: '开户行', width: 200, key: 'bankBranchName', dataIndex: 'bankBranchName' },
        { title: '账号', width: 200, key: 'bankCardNo', dataIndex: 'bankCardNo' },
        { title: '时间', width: 250, key: 'gmtCreate', dataIndex: 'gmtCreate' }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.account = {
      accountId: this.$route.params.accountId,
      accountType: this.$route.params.accountType,
      breadName: this.$route.params.breadName
    }
    this.reloadList()
  },
  methods: {
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const result = await this.$store.dispatch('http', {
        api: 'queryAccountCard',
        query: {
          accountId: this.account.accountId
        },
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.list = result
    }
  }
}
</script>

<style lang="scss" module>
  .container {
    .form__ct {
      padding: 10px 0;
    }

    .form__items {
      padding-top: 6px;
    }
  }
</style>
